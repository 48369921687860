@import "toast.css";
@import "buttons.css";
@import "getStartedModal.css";

:root{
  --primary: #406697;
  --primaryDark: #35557d;
  --primaryLight: #62a9c6;
  --secondary: #62a9c6;
  --darkBlue: #2D3E50;
  --fontColor: #5C5454;
  --white: #FFFFFF;
  --gray100: #FAF7F5;
  --gray200: #CCCCCC;
  --gray300: #9babb3;
  --rejectRed: #ee5454;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  font-size: 16px;
}

body {
  color: var(--fontColor);
  background: var(--primary);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

.gray-background {
  background: var(--gray100);
  min-height: calc(100vh - 50px);
}

.wrapper-padding {
  padding: 1.5rem 1.3rem 1rem 1.3rem;
}

.side-padding {
  padding: 0 1.3rem;
}

.top-bottom-padding {
  padding: 1.5rem 0 1rem 0;
}

.page-padding-bottom {
  padding-bottom: 4rem;
}

.drop-shadow {
  -webkit-box-shadow: 13px 14px 14px -10px rgba(56,56,56,0.4);
  -moz-box-shadow: 13px 14px 14px -10px rgba(56,56,56,0.4);
  box-shadow: 13px 14px 14px -10px rgba(56,56,56,0.4);
}

hr {
  margin: .5rem 0;
  border: none;
}

.hr-white {
  border-top: 1px solid white;
}

.hr-gray {
  border-top: 1px solid var(--gray200);
}

i.icon {
  margin-right: 0;
}


@media only screen and (min-width: 714px) {
  html {
    font-size:16px;
  }

  .wrapper-padding {
    padding: 2rem;
  }

  .side-padding {
    padding: 0 2rem;
  }

  .top-bottom-padding {
    padding: 2rem 0;
  }

  .page-padding-bottom {
    padding-bottom: 4.5rem;
  }

  .text-hover {
    position: relative;
  }

  .text-hover:after {
    content:'';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -6px;
    left: 0;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s;
    z-index: 6;
  }

  .text-hover:hover:after {
    height: 2px;
    transform-origin: bottom left;
    transform: scaleX(1);
    z-index: 6;
  }

  .text-hover-white:after {
    background-color: white;
  }

  .text-hover-black:after {
    background-color: var(--fontColor);
  }
}

@media only screen and (min-width: 1224px) {
  .wrapper-padding {
    padding: 1rem 3rem;
  }

  .side-padding {
    padding: 0 3rem;
  }

  .page-padding-bottom {
    padding-bottom: 4.5rem;
  }
}
