.ui.button.invert-primary-light-button {
  color: var(--primaryLight);
  background: transparent;
  border: 1px solid var(--primaryLight);
}

.ui.button.invert-primary-light-button:hover {
  color: white;
  background: var(--primaryLight);
  border: 1px solid var(--primaryLight);
}

.ui.button.secondary {
  color: white;
  background: var(--secondary);
  border: 1px solid var(--secondary);
}

.ui.button.secondary:hover {
  color: var(--secondary);
  background: transparent;
  border: 1px solid var(--secondary);
}

.ui.button.primary-light {
  color: white;
  background: var(--primaryLight);
  border: 1px solid var(--primaryLight);
}

.ui.button.primary-light:hover {
  color: var(--primaryLight);
  background: transparent;
  border: 1px solid var(--primaryLight);
}

.ui.button.primary-dark {
  color: white;
  background: var(--primaryDark);
  border: 1px solid var(--primaryDark);
}

.ui.button.primary-dark:hover {
  color: var(--primaryDark);
  background: transparent;
  border: 1px solid var(--primaryDark);
}

.ui.button.invert-primary-dark-button {
  color: var(--primaryDark);
  background: transparent;
  border: 1px solid var(--primaryDark);
}

.ui.button.invert-primary-dark-button:hover {
  color: white;
  background: var(--primaryDark);
  border: 1px solid var(--primaryDark);
}

@media only screen and (min-width: 714px) {
  
}