.Toastify__toast-container {
  text-align: center;
  word-break: break-all;
}

.Toastify__toast--error {
  background: #cf2525;
}

@media only screen and (min-width: 714px) {

}