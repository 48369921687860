.ui.modal {
  width: 90%;
}

.ui.modal .description {
  padding: 1.75rem 1rem;
}

.ui.modal .content {
  padding: 1.75rem 1rem;
}

.ui.modal a {
  color: var(--primaryDark);
}

@media only screen and (min-width: 650px) {
 .ui.modal {
   max-width: 500px;
 } 
}

@media only screen and (min-width: 1024px) {
  .ui.modal {
    max-width: 600px;
  }
}